/* Team Gallery start */
.team-gallery{position: relative;margin-bottom: 100px;}
.custom-prev{position: absolute;right: 9%;top: 0;}
.custom-next{position: absolute;right: 3%;top: 0;}
.slick-track{height: 200px;}
  .arrows{ border-radius: 50px; border: 2px solid #efefef;width: 90px;height: 70px;display: flex;align-items: center;justify-content: center;}
  .slick-slide {margin: 0px 20px}
  .slick-list{width: 100%;}
  .team-members img{width: 100%;}
  .team-gallery .content h4{margin-bottom: 25px; padding-top: 0; color: #4A4A4A;font-size: 45px;line-height: 65px;font-weight: bold;font-family: 'Filson Pro Bold';}
  .team-gallery .content p{margin-bottom: 50px; color: rgba(17, 17, 21, 0.5);line-height: 32px;font-size: 22px;font-weight: normal;font-family: 'Filson Pro Regular';width: 1200px;}
  .team-members .figure-caption h2{white-space: nowrap; padding-top: 20px;font-style: normal; color: #4A4A4A;line-height: 36px;font-size: 26px;font-weight: bold;font-family: 'Filson Pro Bold';}
  @media (max-width: 1799.98px){
  .arrows{ border-radius: 50px; border: 2px solid #efefef;width: 65px;height: 45px;display: flex;align-items: center;justify-content: center;}
  .slick-slide {margin: 0px 10px}
  .arrows img{width: 20px;}
  .team-members img{width: 100%;}
  .team-gallery .content h4{margin-bottom: 10px;font-size: 40px;}
  .team-gallery .content p{margin-bottom: 70px; line-height: 26px;font-size: 16px;width: 750px;}
  .team-members .figure-caption h2{ padding-top: 10px;line-height: 26px;font-size: 17px;}
  .team-members .figure-caption p{font-size: 15px;}
  .advisory-board{padding-top: 40px;}
  }
@media (max-width: 1399.98px){
.arrows{ border-radius: 50px; border: 2px solid #efefef;width: 65px;height: 45px;display: flex;align-items: center;justify-content: center;}
.slick-slide {margin: 0px 10px}
.arrows img{width: 20px;}
.team-members img{width: 100%;}
.team-gallery .content h4{margin-bottom: 10px;font-size: 40px;padding-top: 0;}
.team-gallery .content p{ line-height: 26px;font-size: 16px;width: 750px;}
.frequently-asked-questions{padding: 50px 0;}
.team-members .figure-caption h2{ padding-top: 10px;line-height: 26px;font-size: 17px;}
.team-members .figure-caption p{font-size: 15px;}
.advisory-board{padding-top: 40px;}
}
@media (max-width: 1199.98px) {
  .arrows{ border-radius: 50px; border: 2px solid #efefef;width: 65px;height: 45px;display: flex;align-items: center;justify-content: center;}
  .custom-prev{position: absolute;right: 11%;top: 0;}
.custom-next{position: absolute;right: 3%;top: 0;}
  .slick-slide {margin: 0px 10px}
  .arrows img{width: 20px;}
  .team-members img{width: 100%;}
  .team-gallery .content h4{margin-bottom: 10px;font-size: 40px;}
  .team-gallery .content p{margin-bottom: 20px; line-height: 26px;font-size: 16px;width: 750px;}
  .team-members .figure-caption h2{ padding-top: 10px;line-height: 26px;font-size: 14px;}
  .team-members .figure-caption p{font-size: 13px;}
  .advisory-board{padding-top: 40px;}
}
@media (max-width: 991.98px) {
  .custom-prev{position: absolute;right: 13%;top: -4%;}
  .custom-next{position: absolute;right: 3%;top: -4%;}
.team-members img{width: 100%;}
.team-gallery .content h4{font-size: 30px;line-height: 32px;}
.team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
.team-members .figure-caption h2{font-size: 13px;margin-bottom: 2px;text-align: center;}
.team-members .figure-caption p{font-size: 12px;}
}
@media (max-width:767px) {
  .team-members img{width: 100%;}
  .team-gallery .content h4{font-size: 30px;line-height: 32px;}
  .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
  .team-members .figure-caption p{font-size: 12px; text-align: center;}
}
@media (max-width:567px){
  .custom-prev{position: absolute;right: 17%;}
  .custom-next{position: absolute;right: 3%;}
  .arrows{ border-radius: 40px; border: 2px solid #efefef;width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;}

  .team-members img{width: 100%;}
  .team-gallery .content h4{font-size: 30px;line-height: 32px;}
  .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
  .team-members .figure-caption h2{font-size: 22px;margin-bottom: 2px;}
  .team-members .figure-caption p{font-size: 12px; text-align: center;}
}
@media (max-width:480px){
  .team-members img{width: 100%;}
  .team-gallery .content h4{font-size: 30px;line-height: 32px;}
  .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
  .team-members .figure-caption h2{font-size: 22px;margin-bottom: 2px;}
  .team-members .figure-caption p{font-size: 12px; text-align: center;}
}
@media (max-width:367px){
  .team-members img{width: 100%;}
.team-gallery .content h4{font-size: 30px;line-height: 32px;}
.team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
.team-members .figure-caption h2{font-size: 22px;margin-bottom: 2px;}
.team-members .figure-caption p{font-size: 12px; text-align: center;}
.custom-prev{position: absolute;right: 17%;}
.custom-next{position: absolute;right: 3%;}
.arrows{ width: 30px;height: 30px;}
}
@media (max-width:280px){
  .team-members .figure-caption h2 {font-size: 18px;}
  .team-gallery .content h4 {font-size: 22px;}
  .team-gallery .content p {font-size: 14px;}
}