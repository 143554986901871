.footer-links li a{margin: 0px 20px; display: inline; color: #4A4A4A;line-height: 22px;font-size: 22px;font-weight: bold;font-style: normal;font-family: 'Filson Pro Bold';}
.copyright-area{padding-top: 80px;}
.copyright h3{color: #4A4A4A;line-height: 18px;font-size: 18px;font-weight: normal;font-style: normal;font-family: 'Filson Pro Regular';}
.social-links li a{margin: 0px 17px;}
.footer-links li a:hover{color: #D32986;}
.social-links li a:hover svg path {fill:#D32986;}
.tokenomics-para{margin-bottom: 100px;}
.tokenomics-para p{max-width: 670px;}
a{text-decoration: none;}
@media (max-width: 1799.98px){
    .footer-links li a{margin: 0px 20px; display: inline; color: #4A4A4A;line-height: 22px;font-size: 22px;font-weight: bold;font-style: normal;font-family: 'Filson Pro Bold';}
    .copyright-area{padding-top: 150px;}
    .copyright h3{color: #4A4A4A;line-height: 18px;font-size: 16px;font-weight: normal;font-style: normal;font-family: 'Filson Pro Regular';}
    .social-links li a{margin: 0px 17px;}
    .social-links li a svg{width: 25px;}
}
@media (max-width: 1399.98px){
    .footer-links li a{margin: 0px 20px; display: inline; color: #4A4A4A;line-height: 22px;font-size: 22px;font-weight: bold;font-style: normal;font-family: 'Filson Pro Bold';}
    .copyright-area{padding-top: 50px;}
    .copyright h3{color: #4A4A4A;line-height: 18px;font-size: 16px;font-weight: normal;font-style: normal;font-family: 'Filson Pro Regular';}
    .social-links li a{margin: 0px 17px;}
}
@media (max-width: 1199.98px) {
    .footer-links li a{margin: 0px 20px; display: inline; color: #4A4A4A;line-height: 22px;font-size: 22px;font-weight: bold;font-style: normal;font-family: 'Filson Pro Bold';}
    .copyright-area{padding-top: 100px;}
    .copyright h3{color: #4A4A4A;line-height: 18px;font-size: 15px;font-weight: normal;font-style: normal;font-family: 'Filson Pro Regular';}
    .social-links li a{margin: 0px 17px;}
    .social-links li a svg{width: 23px;}
}
@media (max-width: 991.98px) {
    .footer-links li a{margin: 0px 5px; line-height: 22px;font-size: 12px;}
.copyright-area{padding-top: 50px;}
.copyright h5{font-size: 14px;}
.footer-logo img{width: 80px;}
.social-links li a svg{width: 20px;}
}
@media (max-width:767px) {
    .footer-links li a{margin: 0px 5px; line-height: 22px;font-size: 12px;}
    .copyright-area{padding-top: 50px;}
    .copyright h5{font-size: 14px;}
    .footer-logo img{width: 80px;}  
    .social-links li a svg{width: 20px;}
}
@media (max-width:567px){
    .footer-links li a{margin: 0px 5px; line-height: 22px;font-size: 12px;}
    .copyright-area{padding-top: 20px;}
    .copyright h5{font-size: 14px;}
    .footer-logo img{width: 80px;position: relative;top: 30px;}
    .sponser .sponser-img{justify-content: center;}
}
@media (max-width:480px){
    .footer-links li a{margin: 0px 5px; line-height: 22px;font-size: 12px;}
    .copyright-area{padding-top: 15px;}
    .copyright h5{font-size: 14px;}
    .footer-logo img{width: 80px;position: relative;top: 30px;}
    .social-links li a svg{width: 20px;}
}
@media (max-width:367px){
    .footer-links li a{margin: 0px 10px; display: inline; color: #4A4A4A;line-height: 12px;font-size: 12px;font-weight: bold;font-style: normal;font-family: 'Filson Pro Bold';}
.copyright-area{padding-top: 15px;}
.copyright h3{color: #4A4A4A;line-height: 18px;font-size: 14px;font-weight: normal;font-style: normal;font-family: 'Filson Pro Regular';width: 100%;}
.social-links li a{font-size: 15px;margin: 0px 17px;justify-content: center;}
.social-links li a svg{width: 20px;}
.social-links{justify-content: space-around;}
.footer-logo img{width: 80px;position: relative;top: 30px;}
}
@media (max-width:280px){

}