/* Why */
.why{padding-top: 80px;}
.why  h3{margin-bottom: 60px; font-family: 'Filson Pro Regular'; color: #4A4A4A; font-weight: bold;font-size: 45px;line-height: 65px;}
.why  h3 span{color:#d32986;}
.why .content p{ margin-bottom: 40px; font-family: 'Filson Pro Regular'; color: rgba(17, 17, 21, 0.5);font-size: 22px;line-height: 32px;margin: 0px auto;}
/* Mission Vission */
.mission-vission {margin-bottom: 0; background-color: #fafbfd;padding: 40px 50px; border-radius: 20px;}
.mission-vission  h4{margin-bottom: 30px;color: #4A4A4A;font-size: 30px;line-height: 36px;font-family: 'Filson Pro Bold';font-weight: bold;}
.mission-vission  p{ font-size: 22px;line-height: 30px;font-family: 'Filson Pro Regular';color: rgba(17, 17, 21, 0.5);}
@media (max-width: 1799.98px){
    .why{padding-top: 100px;}
  .why .content h3{font-size: 50px;}
  .why .content p{font-size: 22px;line-height: 30px;}
  .mission-vission {padding: 60px 70px; border-radius: 20px;}
  .mission-vission  h4{margin-bottom: 20px;font-size: 22px;line-height: 36px;font-family: 'Filson Pro Bold';font-weight: bold;}
  .mission-vission  p{font-size: 18px;line-height: 24px;}
}
@media (max-width: 1399.98px){
    .why{padding-top: 50px;}
.why .content h3{font-size: 50px;}
.why .content p{font-size: 22px;line-height: 30px;}
.mission-vission{padding: 30px 40px; border-radius: 20px;}
.mission-vission h4{margin-bottom: 20px;font-size: 22px;line-height: 36px;font-family: 'Filson Pro Bold';font-weight: bold;}
.mission-vission p{font-size: 16px;line-height: 24px;width: 100%;}
}
@media (max-width: 1199.98px) {
    .why .content h3{font-size: 40px;}
  .why .content p{font-size: 17px;line-height: 30px;}
  .mission-vission {padding: 20px 70px; border-radius: 20px;}
  .mission-vission  h4{margin-bottom: 20px;font-size: 22px;line-height: 36px;width: 100%;}
  .mission-vission  p{font-size: 13px;line-height: 20px;width: 100%;}
}
@media (max-width: 991.98px) {
    .why{padding-top: 50px;}
.why .content h3{ font-size: 26px;line-height: 38px;}
.why .content p{font-size: 16px;line-height: 25px;text-align: start;margin-bottom: 0;}
.mission-vission {padding: 15px 30px; border-radius: 20px;}
.mission-vission  h4{margin-bottom: 0px;color: #4A4A4A;font-size: 20px;line-height: 36px;font-family: 'Filson Pro Bold';font-weight: bold;}
.mission-vission  p{font-size: 15px;line-height: 20px;width: 100%;}
.vision{margin-bottom: 50px;}
}
@media (max-width:767px) {
    .why{padding-top: 50px;}
  .why .content h3{ font-size: 26px;line-height: 38px;}
  .why .content p{font-size: 16px;line-height: 25px;text-align: start;}
  .mission-vission  p{font-size: 15px;line-height: 20px;width: 100%;}
  .vision{margin-bottom: 50px;}
}
@media (max-width:567px){
    .why{padding-top: 50px;}
  .why .content h3{ font-size: 26px;line-height: 38px;}
  .why .content p{font-size: 16px;line-height: 25px;text-align: start;margin-bottom: 30px;}
  .mission-vission {padding: 10px 10px; border-radius: 20px;}
  .mission-vission  h4{margin-bottom: 20px;color: #4A4A4A;font-size: 20px;line-height: 36px;font-family: 'Filson Pro Bold';font-weight: bold;}
  .mission-vission  p{font-size: 15px;line-height: 30px;width: 100%;}
  .vision{margin-bottom: 50px;}
}
@media (max-width:480px){
    .why{padding-top: 50px;}
  .why .content h3{ font-size: 26px;line-height: 38px;}
  .why .content p{font-size: 16px;line-height: 25px;text-align: start;margin-bottom: 30px;}
  .mission-vission {padding: 10px 10px; border-radius: 20px;}
  .mission-vission  h4{margin-bottom: 20px;color: #4A4A4A;font-size: 20px;line-height: 36px;font-family: 'Filson Pro Bold';font-weight: bold;}
  .mission-vission  p{font-size: 15px;line-height: 22px;width: 100%;}
  .vision{margin-bottom: 50px;}
}
@media (max-width:367px){
    .why{padding-top: 50px;}
.why .content h3{ font-size: 26px;line-height: 38px;}
.why .content p{font-size: 16px;line-height: 25px;text-align: start;margin-bottom: 30px;}
.mission-vission {padding: 10px 10px; border-radius: 20px;}
.mission-vission  h4{margin-bottom: 20px;color: #4A4A4A;font-size: 20px;line-height: 36px;font-family: 'Filson Pro Bold';font-weight: bold;}
.mission-vission  p{font-size: 15px;line-height: 22px;width: 100%;}
.vision{margin-bottom: 50px;}
}
@media (max-width:280px){}