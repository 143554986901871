/* Navbar */
.navbar-toggler i{color: #d32986 !important;}
.nav-wrapper{ position: absolute;width: 100%;z-index: 1; background: linear-gradient(180deg, #000000db 0%, rgba(0, 0, 0, 0) 100%);}
.nav-wrapper .nav-link{cursor: pointer; position: relative; font-family: 'Filson Pro Medium';font-size: 20px;color: #ffffff !important;margin: 15px 20px;font-weight: 500;}
.nav-wrapper .login-btn {position: relative; border-radius: 12px; border: 2px solid #fff;color: #fff;padding: 20px 40px; font-family: 'Filson Pro Regular';font-style: normal;font-weight: bold;font-size: 22px;background-color: transparent;}
.nav-wrapper .nav-link:after{content: "";width: 0;height: 2px;position: absolute;left: 0;bottom: 4px;background: #d32986;transition: all ease-in-out 0.3s}.navbar .nav-item .nav-link:hover:after{width: 100%;}
.nav-wrapper  .nav-link:hover:after{width: 100%;}
.login-btn:hover,.banner .banner-content button:hover, .token-content-members button:hover {border-radius: 0px; color: #d32986;background-color: #000000;box-shadow:none; border-color: #000000;}
.login-btn:before,.login-btn:after, .token-content-members button:before, .token-content-members button:after,.banner .banner-content button:before,.banner .banner-content button:after{content:'';position:absolute;top:0;right:0;height:2px;width:0;background: #d32986;box-shadow:-1px -1px 5px 0px #fff,7px 7px 20px 0px #0003,4px 4px 5px 0px #0002;transition:400ms ease all;}
.login-btn:after,.banner .banner-content button:after,.token-content-members button:after{right:inherit;top:inherit;left:0;bottom:0;}
.login-btn:hover:before,.login-btn:hover:after,.token-content-members button:hover:after,.token-content-members button:hover:before,.banner .banner-content button:hover:before,.banner .banner-content button:hover:after{width:100%;transition:800ms ease all;}
@media (max-width: 1799.98px){
.nav-wrapper .nav-link{font-size: 18px;margin: 10px 30px;}
.nav-wrapper .login-btn { padding: 10px 30px;font-size: 20px;}
}
@media (max-width: 1399.98px){
.nav-wrapper .nav-link{margin: 10px 20px;}
}
@media (max-width: 1199.98px) {
    .nav-wrapper .nav-link{font-size: 14px;margin: 10px 5px;}
    .nav-wrapper .login-btn { padding: 10px 25px;font-size: 20px;}
}
@media (max-width: 991.98px) {
.navbar-brand img{width: 88px;}
.navbar-toggler:focus{box-shadow: none;}
.navbar-collapse{background-color: #000;padding: 20px;}
.nav-wrapper .nav-link{margin: 0;}
.nav-wrapper .login-btn  {padding: 5px 20px;font-size: 18px;}
}
@media (max-width:767px) {
 .navbar-brand img{width: 88px;}
}
@media (max-width:567px){
    .navbar-brand img{width: 88px;}
    .nav-wrapper {position: absolute;width: 100%;z-index: 1;}
}



