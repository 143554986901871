/* join-now */
.join-now{padding-top: 100px;margin-bottom: 40px;}
.join-now-content{ position: relative; background-image: url("../../images/join-now-bg.svg");min-height: 350px;width: 100%; background-repeat: no-repeat;background-size: cover;background-position: center;border-radius: 30px;}
.join-now-content h3{position: absolute;top: 40%; padding: 0px 90px; color: #4A4A4A;line-height: 84px;font-size: 80px;font-weight: bold;font-style: normal;font-family: 'Filson Pro Bold';}
.join-now-btn button{border: none; position: absolute;top: 20%; width: 250px;height: 250px;border-radius: 50%;background: #4A4A4A;color: #FFFFFF;text-align: center;line-height: 46px;font-size: 35px;font-weight: bold;font-family: 'Graphik Bold';cursor: pointer;}
.join-now-btn button:focus{box-shadow: none;}
@media (max-width: 1799.98px){
    .join-now{padding-top: 150px;margin-bottom: 80px;}
    .join-now-content{min-height: 320px;width: 100%; background-repeat: no-repeat;background-size: cover;background-position: center;border-radius: 30px;}
    .join-now-content h3{position: absolute;top: 37%; padding: 0px 90px; color: #4A4A4A;line-height: 84px;font-size: 70px;}
    .join-now-btn button{border: none; position: absolute;top: 20%; width: 200px;height: 200px;border-radius: 50%;font-size: 32px;}
}
@media (max-width: 1399.98px){
    .join-now{padding-top: 50px;margin-bottom: 80px;}
    .join-now-content{min-height: 320px;width: 100%; background-repeat: no-repeat;background-size: cover;background-position: center;border-radius: 30px;}
    .join-now-content h3{position: absolute;top: 37%; padding: 0px 90px; color: #4A4A4A;line-height: 84px;font-size: 70px;}
    .join-now-btn button{border: none; position: absolute;top: 20%; width: 200px;height: 200px;border-radius: 50%;font-size: 32px;}
}
@media (max-width: 1199.98px) {
    .join-now-content{min-height: 250px;width: 100%; background-repeat: no-repeat;background-size: cover;background-position: center;border-radius: 30px;}
    .join-now-content h3{position: absolute;top: 33%; padding: 0px 50px; color: #4A4A4A;line-height: 84px;font-size: 50px;}
    .join-now-btn button{border: none; position: absolute;top: 20%; width: 150px;height: 150px;border-radius: 50%;font-size: 22px;}
}
@media (max-width: 991.98px) {
    .join-now-content{min-height: 250px;width: 100%; background-repeat: no-repeat;background-size: cover;background-position: center;border-radius: 30px;}
 .join-now-content h3{position: absolute;top: 33%; padding: 0px 50px; color: #4A4A4A;line-height: 84px;font-size: 40px;}
 .join-now-btn button{line-height: 25px; right: 5%; border: none; position: absolute;top: 20%; width: 150px;height: 150px;border-radius: 50%;font-size: 22px;}
}
@media (max-width:767px) {
    .join-now{padding-top: 50px;margin-bottom: 80px;}
    .join-now-content{ min-height: 180px;width: 100%;}
    .join-now-content h3{text-align: center; white-space: nowrap; position: absolute;top: 10%;left: 50%;transform: translateX(-50%); padding: 0px 0px; line-height: 34px;font-size: 25px;width: 100%;}
    .join-now-btn button{top: 35%;left: 50%;transform: translateX(-50%); width: 100px;height: 100px;line-height: 26px;font-size: 22px;}
}
@media (max-width:567px){
    .join-now{padding-top: 50px;margin-bottom: 80px;}
  .join-now-content{ min-height: 180px;width: 100%;}
  .join-now-content h3{text-align: center; white-space: nowrap; position: absolute;top: 10%;left: 50%;transform: translateX(-50%); padding: 0px 0px; line-height: 34px;font-size: 25px;width: 100%;}
  .join-now-btn button{top: 35%;left: 50%;transform: translateX(-50%); width: 100px;height: 100px;line-height: 26px;font-size: 22px;}
}
@media (max-width:480px){
    .join-now{padding-top: 50px;margin-bottom: 80px;}
  .join-now-content{ min-height: 180px;width: 100%;}
  .join-now-content h3{text-align: center; white-space: nowrap; position: absolute;top: 10%;left: 50%;transform: translateX(-50%); padding: 0px 0px; line-height: 34px;font-size: 25px;width: 100%;}
  .join-now-btn button{top: 35%;left: 50%;transform: translateX(-50%); width: 100px;height: 100px;line-height: 26px;font-size: 22px;}
}
@media (max-width:367px){
    .join-now{padding-top: 10px;margin-bottom: 0px;}
.join-now-content{ min-height: 180px;width: 100%;}
.join-now-content h3{text-align: center; white-space: nowrap; position: absolute;top: 10%;left: 50%;transform: translateX(-50%); padding: 0px 0px; line-height: 34px;font-size: 25px;width: 100%;}
.join-now-btn button{top: 35%;left: 50%;transform: translateX(-50%); width: 100px;height: 100px;line-height: 26px;font-size: 22px;}
}
@media (max-width:280px){
    .join-now-content h3 {font-size: 18px;}
    .join-now-btn button {
        line-height: 15px;
        font-size: 15px;
    }
    .footer-links li a {
        font-size: 9px;
    }
    .copyright h3{font-size: 10px;text-align: center;}
    .overlay {
        width: 80px;
        height: 80px;}
}