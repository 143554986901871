/* Token Allocation */
.token-allocation{padding-top: 150px;position: relative;}
.token-allocation .content{background: rgba(17, 17, 21, 0.05);border-radius: 40px;padding: 50px 70px;}
.token-allocation h4{margin-bottom: 80px; color: #4A4A4A;line-height: 65px;font-size:45px;font-weight: bold;font-family: 'Graphik Bold';}
.token-allocation .content ul li{ margin: 20px 0px; font-family: 'Graphik Regular'; color: rgba(17, 17, 21, 0.5);line-height: 32px;font-size: 22px;font-weight: normal;}
.token-allocation .content ul li:nth-child(1):before{content: "";background: #D32986;width: 17px;height: 17px;border-radius: 50px;position: absolute;left: 4%;}
.token-allocation .content ul li:nth-child(2):before{content: "";background: #38668F;width: 17px;height: 17px;border-radius: 50px;position: absolute;left: 4%;}
.token-allocation .content ul li:nth-child(3):before{content: "";background: #F9B815;width: 17px;height: 17px;border-radius: 50px;position: absolute;left: 4%;}
.token-allocation .content ul li:nth-child(4):before{content: "";background: #4A4A4A;width: 17px;height: 17px;border-radius: 50px;position: absolute;left: 4%;}
.token-allocation .content ul li:nth-child(5):before{content: "";background: #E994C3;width: 17px;height: 17px;border-radius: 50px;position: absolute;left: 4%;}
.graph{position: relative;}
.overlay{box-shadow: 0px 0px 90px rgba(211, 41, 134, 0.15); width: 260px;height: 260px;border-radius: 50%;display: flex;align-items: center;justify-content: center;padding: 55px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}
.overlay img{width: 180px;}
@media (max-width: 1799.98px){
    .token-allocation{padding-top: 100px;}
   .token-allocation .content{padding: 30px 50px;}
   .token-allocation h4{margin-bottom: 60px; font-size: 40px;}
   .token-allocation .content ul li{ margin: 10px 0px;line-height: 26px;font-size: 18px;}
   .token-allocation .content ul li:nth-child(1):before{width: 10px;height: 10px;left: 7%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(2):before{width: 10px;height: 10px;left: 7%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(3):before{width: 10px;height: 10px;left: 7%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(4):before{width: 10px;height: 10px;left: 7%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(5):before{width: 10px;height: 10px;left: 7%;margin-top: 10px;}
   .token-allocation-image img{width: 450px;}
   .overlay{box-shadow: 0px 0px 90px rgba(211, 41, 134, 0.15); width: 160px;height: 160px;border-radius: 50%;display: flex;align-items: center;justify-content: center;padding: 25px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}
   .overlay img{width: 100px;}
}
@media (max-width: 1399.98px) {
   .token-allocation{padding-top: 100px;}
   .token-allocation .content{padding: 30px 50px;}
   .token-allocation h4{margin-bottom: 60px; font-size: 40px;}
   .token-allocation .content ul li{ margin: 10px 0px;line-height: 26px;font-size: 18px;}
   .token-allocation .content ul li:nth-child(1):before{width: 10px;height: 10px;left: 5%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(2):before{width: 10px;height: 10px;left: 5%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(3):before{width: 10px;height: 10px;left: 5%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(4):before{width: 10px;height: 10px;left: 5%;margin-top: 10px;}
   .token-allocation .content ul li:nth-child(5):before{width: 10px;height: 10px;left: 5%;margin-top: 10px;}
   .token-allocation-image img{width: 450px;}
   .overlay{box-shadow: 0px 0px 90px rgba(211, 41, 134, 0.15); width: 160px;height: 160px;border-radius: 50%;display: flex;align-items: center;justify-content: center;padding: 25px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}
   .overlay img{width: 100px;}
}

@media (max-width: 991.98px) {
    .token-allocation{padding-top: 0px;}
 .token-allocation .content{padding: 10px 30px;margin-bottom: 50px;}
 .token-allocation h4{margin-bottom: 10px; font-size: 25px;}
 .token-allocation .content ul li{ margin: 20px 0px; line-height: 20px;font-size: 14px;font-weight: normal;}
 .token-allocation .content ul li:nth-child(1):before{width: 8px;height: 8px;left: 8%;}
 .token-allocation .content ul li:nth-child(2):before{width: 8px;height: 8px;left: 8%;}
 .token-allocation .content ul li:nth-child(3):before{width: 8px;height: 8px;left: 8%;}
 .token-allocation .content ul li:nth-child(4):before{width: 8px;height: 8px;left: 8%;}
 .token-allocation .content ul li:nth-child(5):before{width: 8px;height: 8px;left: 8%;}
 .graph{position: relative;}
 .overlay{box-shadow: 0px 0px 90px rgba(211, 41, 134, 0.15); width: 120px;height: 120px;border-radius: 50%;display: flex;align-items: center;justify-content: center;}
 .overlay img{width: 80px;}
}
@media (max-width:767px) {
    .token-allocation{padding-top: 50px;}
   .token-allocation .content{padding: 10px 30px;margin-bottom: 50px;}
   .token-allocation h4{margin-bottom: 10px; font-size: 25px;}
   .token-allocation .content ul li{ margin: 20px 0px; line-height: 20px;font-size: 14px;font-weight: normal;}
   .token-allocation .content ul li:nth-child(1):before{width: 10px;height: 10px;}
   .token-allocation .content ul li:nth-child(2):before{width: 10px;height: 10px;}
   .token-allocation .content ul li:nth-child(3):before{width: 10px;height: 10px;}
   .token-allocation .content ul li:nth-child(4):before{width: 10px;height: 10px;}
   .graph{position: relative;}
   
   .list-unstyled{padding-left: 25px;}
   .overlay img{width: 100px;}
}
@media (max-width:567px){
    .token-allocation{padding-top: 50px;}
   .token-allocation .content{padding: 10px 30px;margin-bottom: 50px;}
   .token-allocation h4{margin-bottom: 10px; font-size: 25px;}
   .token-allocation .content ul li{ margin: 20px 0px; line-height: 20px;font-size: 14px;font-weight: normal;}
   .token-allocation .content ul li:nth-child(1):before{width: 10px;height: 10px;left: 18%;}
   .token-allocation .content ul li:nth-child(2):before{width: 10px;height: 10px;left: 18%;}
   .token-allocation .content ul li:nth-child(3):before{width: 10px;height: 10px;left: 18%;}
   .token-allocation .content ul li:nth-child(4):before{width: 10px;height: 10px;left: 18%;}
   .token-allocation .content ul li:nth-child(5):before{width: 10px;height: 10px;left: 18%;}
   .graph{position: relative;}
}
@media (max-width:480px){
    .token-allocation{padding-top: 50px;}
   .token-allocation .content{padding: 10px 30px;margin-bottom: 50px;}
   .token-allocation h4{margin-bottom: 10px; font-size: 25px;}
   .token-allocation .content ul li{ margin: 20px 0px; line-height: 20px;font-size: 14px;font-weight: normal;}

   .graph{position: relative;}
   .token-allocation-image img {width: 100%;}
}
@media (max-width:367px){
   .token-allocation{padding-top: 50px;}
 .token-allocation .content{padding: 10px 30px;margin-bottom: 50px;}
 .token-allocation h4{margin-bottom: 10px; font-size: 25px;}
 .token-allocation .content ul li{ margin: 20px 0px; line-height: 20px;font-size: 14px;font-weight: normal;}
 .token-allocation .content ul li:nth-child(1):before{width: 10px;height: 10px;left: 12%;}
 .token-allocation .content ul li:nth-child(2):before{width: 10px;height: 10px;left: 12%;}
 .token-allocation .content ul li:nth-child(3):before{width: 10px;height: 10px;left: 12%;}
 .token-allocation .content ul li:nth-child(4):before{width: 10px;height: 10px;left: 12%;}
 .token-allocation .content ul li:nth-child(5):before{width: 10px;height: 10px;left: 12%;}
 
 .graph{position: relative;}
 .token-allocation-image img {width: 100%;}
 .big-plan-1 .content h3{line-height: 45px;}
}
@media (max-width:280px){}