/* About */
.about{padding-top: 50px;}
.about .content h3{margin-bottom: 25px; font-family: 'Filson Pro Regular'; color: #4A4A4A; font-weight: bold;font-size: 45px;line-height: 65px;}
.about .content h3 span{color:#d32986;}
.about .content p{font-family: 'Filson Pro Regular'; color: rgba(17, 17, 21, 0.5);font-size: 22px;line-height: 32px;margin-bottom: 30px;}
@media (max-width: 1799.98px){
    .about{padding-top: 200px;}
    .about .content h3{font-size: 50px;line-height: 62px;}
    .about .content p{width: 630px;font-size: 22px;line-height: 30px;}
    .ico-image img{width: 400px;}
}
@media (max-width: 1399.98px){
    .about{padding-top: 100px;}
.about .content h3{font-size: 50px;line-height: 62px;}
.about .content p{width: 630px;font-size: 22px;line-height: 30px;}
.ico-image img{width: 400px;}
}
@media (max-width: 1199.98px) {
    .about{padding-top: 60px;}
    .about .content h3{font-size: 30px;line-height: 32px;}
    .about .content p{width: 450px;font-size: 15px;line-height: 25px;}
    .ico-image img{width: 320px;}
}
@media (max-width: 991.98px) {
    .about{padding-top: 50px;}
.about .content h3{ font-size: 26px;line-height: 30px;width: 100%;}
.about .content p{width: 100%;font-size: 16px;line-height: 26px;margin-bottom: 10px;}
}
@media (max-width:767px) {
    .about{padding-top: 50px;}
  .about .content h3{ font-size: 26px;line-height: 30px;width: 100%;}
}
@media (max-width:567px){
    .about{padding-top: 50px;}
  .about .content h3{ font-size: 26px;line-height: 30px;width: 100%;}
  .about .content p{width: 100%;font-size: 16px;line-height: 26px;}
}
@media (max-width:480px){
    .about{padding-top: 50px;}
    .about .content h3{ font-size: 26px;line-height: 30px;width: 100%;}
    .about .content p{width: 100%;font-size: 16px;line-height: 26px;}
}
@media (max-width:367px){
    .about{padding-top: 50px;}
.about .content h3{ font-size: 26px;line-height: 30px;width: 100%;}
.about .content p{width: 100%;font-size: 16px;line-height: 26px;}
}
@media (max-width:280px){}