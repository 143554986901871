
 .ecosytem{padding: 300px 0px;background: rgba(237, 238, 239, 0.4);margin-bottom: 70px;}
.dotss{border: 5px solid white; top: 43%; width: 36px;height:36px;border-radius: 50%;background-color: #D62287;position: absolute;}
.ecosytem{background-image: url("../../images//dots-bg.svg");background-repeat: no-repeat;background-position: center;}
.circle-1:after{margin-left: 12px; position: absolute;height: 200px;width: 5px;content: "";background-color: #D62287;top: 40%;}
.circle-3:after{margin-left: 12px; position: absolute;height: 130px;width: 5px;content: "";background-color: #D62287;top: 40%;}
.circle-5:after{margin-left: 12px; position: absolute;height: 200px;width: 5px;content: "";background-color: #D62287;}
.circle-7:after{margin-left: 12px; position: absolute;height: 130px;width: 5px;content: "";background-color: #D62287;}
.circle-9:before{margin-left: 12px; position: absolute;height: 50px;width: 5px;content: "";background-color: #D62287;bottom: 72%;}
.circle-2:after{margin-left: 12px; position: absolute;height: 200px;width: 5px;content: "";background-color: #D62287;bottom: 72%;}
.circle-4:before{margin-left: 12px; position: absolute;height: 130px;width: 5px;content: "";background-color: #D62287;bottom: 72%;}
.circle-6:before{margin-left: 12px; position: absolute;height: 200px;width: 5px;content: "";background-color: #D62287;top: 35%;}
.circle-8:before{margin-left: 12px; position: absolute;height: 130px;width: 5px;content: "";background-color: #D62287;top: 42%;}
.circle-10:before{margin-left: 12px; position: absolute;height: 200px;width: 5px;content: "";background-color: #D62287;top: 35%;}
.statics  .dates{color: #4A4A4A;text-transform: capitalize;text-align: center;line-height: 26px;font-size: 24px;font-weight: bold;font-family: 'Filson Pro Bold'; margin-bottom: 20px;} 
.statics  .about{color: rgba(17, 17, 21, 0.5);text-align: center;line-height: 20px;font-size: 18px;font-weight: normal;font-family: 'Filson Pro Regular';position:relative;}
.statics {position: relative;}
.custom-about{color: rgba(17, 17, 21, 0.5);text-align: center;line-height: 20px;font-size: 18px;font-weight: normal;font-family: 'Filson Pro Regular';width: 230px;}
.custom-about::before{position: absolute;content: "";width: 80px;height: 5px;background-color: #D62287;left: -40%;bottom: 5%;}
.statics  .dates-1{color: #4A4A4A;text-transform: capitalize;text-align: center;line-height: 26px;font-size: 24px;font-weight: bold;font-family: 'Filson Pro Bold';position: absolute;top: 60%; } 
.statics  .about-1{color: rgba(17, 17, 21, 0.5);text-align: center;line-height: 20px;font-size: 18px;font-weight: normal;font-family: 'Filson Pro Regular';position: relative;}
 .experties p{width: 1324px; color: #4A4A4A;line-height: 36px;font-size: 26px;font-weight: normal;font-style: normal;font-family: 'Filson Pro Regular';}
 .experties{background: rgba(237, 238, 239, 0.4);padding-top: 100px;}
 .para-1{top: 100%;position: absolute;}
 .para-2{bottom: 110%;}
 .para-3{top: 75%;position: absolute;}
 .para-4{bottom: 65%;position: absolute;}
 .para-5{top: 110%;position: absolute;}
 .para-6{bottom: 100%;position: absolute;}
 .para-7{top: 65%;position: absolute;}
 .para-8{bottom: 60%;position: absolute;}
 .para-9{bottom: 28%;position: absolute;}
 .para-10{top: 100%;position: absolute;}
 .dat-4{padding-left: 60px;}
 .dat-6{padding-left: 40px;}
 .dat-1{padding-left: 10px;}
 .dat-8{padding-left: 10px;}
 @media (max-width: 1799.98px){
    .ecosytem{padding: 100px 0px;background: rgba(237, 238, 239, 0.4);margin-bottom: 70px;}
    .dotss{ top: 49%; width: 25px;height:25px;border-radius: 50%;background-color: #D62287;position: absolute;}
    .ecosytem{background-image: url("../../images/dots-bg.svg");background-repeat: no-repeat;background-position: center;width: 100%;background-size: contain;}
    .circle-1:after{margin-left: 5px;height: 140px;top: 40%;}
    .circle-3:after{margin-left: 5px;height: 100px;top: 40%;}
    .circle-5:after{margin-left: 5px;height: 150px;}
    .circle-9:before{margin-left: 5px;height: 50px;bottom: 72%;}
    .circle-2:after{margin-left: 5px;height: 150px;bottom: 72%;}
    .circle-4:before{margin-left: 5px;height: 100px;bottom: 72%;}
    .circle-6:before{margin-left: 5px;height: 150px;top: 35%;}
    .circle-8:before{margin-left: 5px;height: 130px;top: 42%;}
    .circle-10:before{margin-left: 5px;height: 150px;top: 35%;}
    .statics  .dates{line-height: 16px;font-size: 14px; margin-bottom: 20px;position: absolute;top: 37%;} 
    .statics  .about{font-size: 14px;position:relative;}
    .custom-about{line-height: 16px;font-size: 12px;padding-left: 20px;}
    .custom-about::before{left: -32%;bottom:25%;}
    .statics  .dates-1{line-height: 16px;font-size: 14px;position: absolute;top: 55%; } 
    .statics  .about-1{line-height: 16px;font-size: 12px;position: relative;}
    .experties p{line-height: 30px;font-size: 19px;}
    .platform-design .custom-about{position: relative;bottom: 24%;}
    .para-1{top: 35%;}
    .para-2{bottom: 30%;}
    .para-3{top: 20%;}
    .para-4{bottom:7%;}
    .para-5{top: 35%;}
    .para-6{bottom: 20%;}
    .para-7{top: 20%;}
    .para-8{bottom: 4%;}
    .para-9{bottom: -8%;}
    .para-10{top: 35%;}
    .dat-1{padding-left: 30px;}
    .dat-2{padding-left: 20px;}
    .dat-4{padding-left: 50px;}
     .dat-6{padding-left: 40px;}
     .dat-7{padding-left: 20px;}
     .dat-8{padding-left: 20px;}
     .dat-5{padding-left: 60px;}
     .dat-3{padding-left: 60px;}
 }
@media (max-width: 1399.98px){
    .ecosytem{padding: 180px 0px;background: rgba(237, 238, 239, 0.4);margin-bottom: 70px;}
.dotss{ top: 49%; width: 25px;height:25px;border-radius: 50%;background-color: #D62287;position: absolute;}
.ecosytem{background-image: url("../../images/dots-bg.svg");background-repeat: no-repeat;background-position: center;width: 100%;background-size: contain;}
.circle-1:after{margin-left: 5px;height: 140px;top: 40%;}
.circle-3:after{margin-left: 5px;height: 100px;top: 40%;}
.circle-5:after{margin-left: 5px;height: 150px;}
.circle-9:before{margin-left: 5px;height: 50px;bottom: 72%;}
.circle-2:after{margin-left: 5px;height: 150px;bottom: 72%;}
.circle-4:before{margin-left: 5px;height: 100px;bottom: 72%;}
.circle-6:before{margin-left: 5px;height: 150px;top: 35%;}
.circle-8:before{margin-left: 5px;height: 130px;top: 42%;}
.circle-10:before{margin-left: 5px;height: 150px;top: 35%;}
.statics  .dates{line-height: 16px;font-size: 12px; margin-bottom: 20px;position: absolute;top: 30%;} 
.statics  .about{font-size: 14px;position:relative;}
.custom-about{line-height: 16px;font-size: 12px;padding-left: 20px;}
.custom-about::before{left: -28%;bottom:25%;}
.statics  .dates-1{line-height: 16px;font-size: 12px;position: absolute;top: 53%; } 
.statics  .about-1{line-height: 16px;font-size: 12px;position: relative;}
.experties p{line-height: 30px;font-size: 19px;margin-bottom: 60px;}
.platform-design .custom-about{position: relative;bottom: 24%;}
.para-1{top: 80%;}
.para-2{bottom: 70%;}
.para-3{top: 56%;}
.para-4{bottom:35%;}
.para-5{top: 85%;}
.para-6{bottom: 70%;}
.para-7{top: 60%;}
.para-8{bottom: 50%;}
.para-9{bottom: 15%;}
.para-10{top: 80%;}
.dat-1{padding-left: 10px;}
.dat-2{padding-left: 10px;}
.dat-3{padding-left: 40px;}
.dat-4{padding-left: 30px;}
.dat-5{padding-left: 40px;}
.dat-6{padding-left: 20px;}
.dat-7{padding-left: 10px;}
}
@media (max-width: 1199.98px) {
    .ecosytem{padding: 150px 0px;background: rgba(237, 238, 239, 0.4);margin-bottom: 70px;}
.dotss{ top: 49%; width: 25px;height:25px;border-radius: 50%;background-color: #D62287;position: absolute;}
.ecosytem{background-image: url("../../images/dots-bg.svg");background-repeat: no-repeat;background-position: center;width: 100%;background-size: contain;}
.circle-1:after{margin-left: 5px;height: 100px;top: 40%;}
.circle-3:after{margin-left: 5px;height: 100px;top: 40%;}
.circle-5:after{margin-left: 5px;height: 100px;}
.circle-9:before{margin-left: 5px;height: 30px;bottom: 72%;}
.circle-2:after{margin-left: 5px;height: 100px;bottom: 72%;}
.circle-4:before{margin-left: 5px;height: 60px;bottom: 72%;}
.circle-6:before{margin-left: 5px;height: 100px;top: 35%;}
.circle-8:before{margin-left: 5px;height: 90px;top: 42%;}
.circle-10:before{margin-left: 5px;height: 100px;top: 35%;}
.statics  .dates{line-height: 14px;font-size: 10px; margin-bottom: 20px;position: absolute;top: 27%;} 
.statics  .about{font-size: 14px;position:relative;}
.custom-about{line-height: 16px;font-size: 12px;padding-left: 5px;width: 110px;}
.custom-about::before{left: -47%;bottom:-10%;width: 50px;}
.statics  .dates-1{line-height: 14px;font-size: 10px;position: absolute;top: 60%; } 
.statics  .about-1{line-height: 14px;font-size: 12px;position: relative;}
.experties p{line-height: 22px;font-size: 16px;margin-bottom: 20px;}
.platform-design .custom-about{position: relative;bottom: 14%;}
.para-1{top: 90%;}
.para-2{bottom: 50%;}
.para-3{top: 85%;}
.para-4{bottom:35%;}
.para-5{top: 80%;}
.para-6{bottom: 58%;}
.para-7{top: 90%;}
.para-8{bottom: 25%;}
.para-9{bottom: 5%;}
.para-10{top: 85%;}
}
@media (max-width: 991.98px) {
    .ecosytem{padding: 150px 0px;background: rgba(237, 238, 239, 0.4);margin-bottom: 0px;}
.dotss{top: 49%; width: 25px;height:25px;border-radius: 50%;background-color: #D62287;position: absolute;}
.ecosytem{background-image: url("../../images/dots-bg.svg");background-repeat: no-repeat;background-position: center;width: 100%;background-size: contain;}
.circle-1:after{margin-left: 5px;height: 90px;top: 40%;}
.circle-3:after{margin-left: 5px;height: 90px;top: 40%;}
.circle-5:after{margin-left: 5px;height: 90px;}
.circle-9:before{margin-left: 5px;height: 30px;bottom: 72%;}
.circle-2:after{margin-left: 5px;height: 90px;bottom: 72%;}
.circle-4:before{margin-left: 5px;height: 60px;bottom: 72%;}
.circle-6:before{margin-left: 5px;height: 90px;top: 35%;}
.circle-8:before{margin-left: 5px;height: 90px;top: 42%;}
.circle-10:before{margin-left: 5px;height: 90px;top: 35%;}
.statics  .dates{line-height: 14px;font-size: 9px; margin-bottom: 20px;position: absolute;top: 27%;} 
.statics  .about{font-size: 10px;position:relative;}
.custom-about{line-height: 16px;font-size: 10px;padding-left: 5px;width: 110px;}
.custom-about::before{left: -32%;bottom:-10%;width: 50px;}
.statics  .dates-1{line-height: 14px;font-size: 9px;position: absolute;top: 60%; } 
.statics  .about-1{line-height: 14px;font-size: 10px;position: relative;}
.experties p{line-height: 22px;font-size: 16px;margin-bottom: 20px;}
.platform-design .custom-about{position: relative;bottom: 14%;}
.para-1{top: 90%;}
.para-2{bottom: 50%;}
.para-3{top: 85%;}
.para-4{bottom:35%;}
.para-5{top: 80%;}
.para-6{bottom: 58%;}
.para-7{top: 90%;}
.para-8{bottom: 25%;}
.para-9{bottom: 5%;}
.para-10{top: 85%;}
.dat-3{padding-left: 20px;}
.dat-4{padding-left: 20px;}
.dat-5{padding-left: 20px;}
}
@media (max-width:767px) {}
@media (max-width:567px){}
@media (max-width:480px){}
@media (max-width:367px){}
@media (max-width:280px){}