/* frequently-asked-questions */
.frequently-asked-questions{padding-top: 0;}
.frequently-asked-questions .content h3{margin-bottom: 70px; color: #4A4A4A;text-align: center;line-height: 60px;font-size: 45px;font-weight: bold;font-family: 'Filson Pro Bold'; }
.custom-accordion .accordion-body ul li{color: rgba(17, 17, 21, 0.5);line-height: 32px;font-size: 22px;font-weight: normal;font-style: normal;font-family: 'Filson Pro Regular';margin: 25px 0px; }
.custom-accordion .accordion-button{ background-color: transparent; color: #D32986;line-height: 32px;font-size: 22px;font-weight: 400;font-style: normal;font-family: 'Filson Pro Regular';}
.custom-accordion .accordion-button:focus{box-shadow: none;}
.accordion-button::after{background-image: url("../../images/black-arrow.svg");background-size: contain;}
.accordion-item .accordion-button.collapsed{color: #4A4A4A;}
@media (max-width: 1799.98px){
    .frequently-asked-questions .content h3{margin-bottom: 50px;font-size: 40px; }
    .custom-accordion .accordion-body ul li{line-height: 28px;font-size: 20px; }
    .custom-accordion .accordion-button{font-size: 20px;}
}
@media (max-width: 1399.98px){
    .frequently-asked-questions .content h3{margin-bottom: 50px;font-size: 40px; }
.custom-accordion .accordion-body ul li{line-height: 28px;font-size: 20px; }
.custom-accordion .accordion-button{font-size: 20px;}
}
@media (max-width: 1199.98px) {
    .frequently-asked-questions .content h3{margin-bottom: 50px;font-size: 30px; }
    .custom-accordion .accordion-body ul li{line-height: 18px;font-size: 15px; }
    .custom-accordion .accordion-button{font-size: 17px; line-height: 20px;}
}
@media (max-width: 991.98px) {
    .frequently-asked-questions{padding-top: 0;}
.frequently-asked-questions .content h3{margin-bottom: 30px; line-height: 36px;font-size: 25px;}
.custom-accordion .accordion-body ul li{line-height: 26px;font-size: 14px;margin: 25px 0px; }
.custom-accordion .accordion-button{line-height: 20px;font-size: 14px;}
}
@media (max-width:767px) {
    .frequently-asked-questions{padding-top: 0;}
    .frequently-asked-questions .content h3{margin-bottom: 30px; line-height: 36px;font-size: 25px;}
    .custom-accordion .accordion-body ul li{line-height: 26px;font-size: 14px;margin: 25px 0px; }
    .custom-accordion .accordion-button{line-height: 20px;font-size: 14px;}
}
@media (max-width:567px){
    .frequently-asked-questions{padding-top: 0;}
    .frequently-asked-questions .content h3{margin-bottom: 30px; line-height: 36px;font-size: 25px;}
    .custom-accordion .accordion-body ul li{line-height: 26px;font-size: 14px;margin: 25px 0px; }
    .custom-accordion .accordion-button{line-height: 20px;font-size: 14px;}
}
@media (max-width:480px){
    .frequently-asked-questions{padding-top: 0;}
    .frequently-asked-questions .content h3{margin-bottom: 30px; line-height: 36px;font-size: 25px;}
    .custom-accordion .accordion-body ul li{line-height: 26px;font-size: 14px;margin: 25px 0px; }
    .custom-accordion .accordion-button{line-height: 20px;font-size: 14px;}
}
@media (max-width:367px){
    .frequently-asked-questions{padding-top: 0;}
.frequently-asked-questions .content h3{margin-bottom: 30px; line-height: 36px;font-size: 25px;}
.custom-accordion .accordion-body ul li{line-height: 26px;font-size: 14px;margin: 25px 0px; }
.custom-accordion .accordion-button{line-height: 20px;font-size: 14px;}
}
@media (max-width:280px){.frequently-asked-questions .content h3 {font-size: 20px;}}