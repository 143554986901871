/* Big Plan */
.big-plan{padding: 150px 0;}
.big-plan .content h3{font-family: 'Filson Pro Bold'; color:#4A4A4A;font-weight: bold;font-size: 80px;line-height: normal;letter-spacing: -0.05em;}
.big-plan .content h3 span{color: #D32986;}

@media (max-width: 1799.98px){
    /* .big-plan .content h3{font-size: 80px;}
  .big-plan .content h3{font-size: 80px;} */
}
@media (max-width: 1199.98px) {
    .big-plan .content h3{font-size: 50px;}
  .big-plan .content h3{font-size: 70px;}
}
@media (max-width: 991.98px) {
.big-plan{padding: 50px 0;}
  .membership-level{margin-bottom: 10px;}
.membership-level{padding-top: 40px; }
.membership-level .content h3{ font-size: 26px;line-height: 32px;width: 100%;margin-bottom: 20px;}
}
@media (max-width:767px) {
    .big-plan .content h3{font-size: 35px;}
}
@media (max-width:567px){
    .big-plan .content h3{font-size: 27px;}
    .big-plan{padding: 20px 0;}
}
