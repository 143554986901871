/* Membership Level */
.membership-level{padding-top: 80px; }
.membership-level .content h3{ margin-bottom: 65px; font-family: 'Filson Pro bold'; color: #4A4A4A; font-weight: 700;font-size: 45px;line-height: 65px;}
.membership-level .content h3 span{color:#d32986;}
.membership-level .custom-card{background: #EDEEEF;border-radius: 20px;border: none;padding: 40px 40px 60px 40px;height: 100%;}
.membership-level .custom-card h5{margin-bottom: 40px; font-size: 30px;line-height: 36px;color: #D32986;font-style: normal;font-weight: bold;font-family: 'Filson Pro Bold';}
.membership-level .custom-card p{color: #4A4A4A;font-weight: normal;font-size: 22px;line-height: 30px;font-family: 'Filson Pro Regular';}
@media (max-width: 1799.98px){
    .membership-level .content h3{ margin-bottom: 45px; font-size: 40px;line-height: 32px;}
    /* .membership-level .custom-card{padding: 60px 60px 90px 60px;} */
    .membership-level .custom-card h5{margin-bottom: 30px; font-size: 20px;line-height: 26px;}
    .membership-level .custom-card p{font-size: 17px;line-height: 22px;text-align: start;}
}
@media (max-width: 1399.98px){
    .membership-level .content h3{ margin-bottom: 45px; font-size: 40px;line-height: 32px;}
.membership-level .custom-card{padding: 40px 40px 50px 40px;}
.membership-level .custom-card h5{margin-bottom: 30px; font-size: 20px;line-height: 26px;}
.membership-level .custom-card p{font-size: 17px;line-height: 22px;text-align: start;}
}
@media (max-width: 1199.98px) {
    .membership-level .content h3{ margin-bottom: 45px; font-size: 30px;line-height: 32px;}
    .membership-level .custom-card{padding: 22px 16px 22px 13px;}
    .membership-level .custom-card h5{margin-bottom: 10px; font-size: 16px;line-height: 26px;}
    .membership-level .custom-card p{font-size: 13px;line-height: 22px;text-align: start;}
}
@media (max-width: 991.98px) {
    .membership-level{margin-bottom: 10px;}
.membership-level{padding-top: 40px; }
.membership-level .content h3{ font-size: 26px;line-height: 32px;width: 100%;margin-bottom: 20px;}
}
@media (max-width:767px) {
  .membership-level{padding-top: 40px; }
  .membership-level .content h3{ font-size: 26px;line-height: 32px;width: 100%;}
  .membership-level .custom-card {
    padding: 15px 15px 20px 15px;}
}
@media (max-width:567px){
    .membership-level{padding-top: 40px; }
    .membership-level .content h3{line-height: 32px;width: 100%;}
}
@media (max-width:480px){
  .membership-level{padding-top: 40px; }
  .membership-level .content h3{line-height: 32px;width: 100%;}
}
@media (max-width:367px){
.membership-level{padding-top: 40px; }
.membership-level .content h3{line-height: 32px;width: 100%;}
.big-plan .content h3{line-height: 45px;}
}
@media (max-width:280px){}