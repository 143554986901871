@font-face {font-family: 'Filson Pro Bold';src: url('./font/FilsonPro-Bold.woff2') format('woff2'),url('./font/FilsonPro-Bold.woff') format('woff');font-style: normal;font-display: swap;}
@font-face {font-family: 'Filson Pro Regular';src: url('./font/FilsonPro-Regular.woff2') format('woff2'),url('./font/FilsonPro-Regular.woff') format('woff');font-style: normal;font-display: swap;}
@font-face {font-family: 'Filson Pro Medium';src: url('./font/FilsonPro-Medium.woff2') format('woff2'),url('./font/FilsonPro-Medium.woff') format('woff');font-weight: 400;font-style: normal;font-display: swap;}
@font-face {font-family: 'Graphik Regular';src: url('./font/Graphik-Regular.woff2') format('woff2'),url('./font/Graphik-Regular.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}
@font-face {font-family: 'Graphik Bold';src: url('./font/Graphik-Bold.woff2') format('woff2'),url('./font/Graphik-Bold.woff') format('woff'); font-weight: bold;font-style: normal;font-display: swap;}

body{overflow-x: hidden;}
* {scrollbar-width: auto;scrollbar-color: #8f54a0 #ffffff;}
  *::-webkit-scrollbar {width: 16px;}
  *::-webkit-scrollbar-track {background: #ffffff;}
  *::-webkit-scrollbar-thumb {background-color: #d32986;border-radius: 10px;border: 3px solid #ffffff;}
  .custom-container{padding-left: 50px;padding-right: 50px;}
@media (max-width: 991px){
  .custom-container{padding-left: 30px;padding-right: 30px;}

}
@media (max-width: 767px){
  .custom-container{padding-left: 20px;padding-right: 20px;}
}
@media (max-width: 575px){
  .custom-container{padding-left: 15px;padding-right: 15px;}

}