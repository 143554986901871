 /* Budget Allocation */
 .budget-allocation{padding-top: 150px;position: relative;}
 .budget-allocation h4{margin-bottom: 80px; color: #4A4A4A;line-height: 65px;font-size: 45px;font-weight: bold;font-family: 'Graphik Bold';}
 .percentage-card{min-height: 220px;margin: 20px 0px; background: rgba(17, 17, 21, 0.05);border-radius: 30px;padding-left: 30px; padding-right: 30px; padding-top: 30px;}
 .dot {margin-bottom: 30px; height: 23px;width: 23px;border-radius: 50%;display: inline-block;}
 .dot-1{background: #D32986;}
 .dot-2{background: #38668F;}
 .dot-3{background: #F9B815;}
 .dot-4{background: #4A4A4A;}
 .dot-5{background: #89899E;}
 .dot-6{background: #E994C3;}
.percentage-value-1{color: #D32986;}
.percentage-value-2{color: #38668F;}
.percentage-value-3{color: #F9B815;}
.percentage-value-4{color: #4A4A4A;}
.percentage-value-5{color: #89899E;}
.percentage-value-6{color: #E994C3;}
.percentage-card p{color: rgba(17, 17, 21, 0.5);line-height: 24px;font-size: 22px;font-weight: normal;font-family: 'Graphik Regular';margin-bottom: 20px;}
.percentage-card h3{line-height: 50px;font-size:45px;font-weight: bold;font-family: 'Graphik Bold';}
@media (max-width: 1799.98px){
    .budget-allocation h4{margin-bottom: 40px; font-size: 40px;}
    .percentage-card{min-height: 190px;margin: 10px 0px;}
    .dot {margin-bottom: 20px; height: 17px;width: 17px;border-radius: 50%;}
   .percentage-card p{width: 159px; font-size: 15px;margin-bottom: 4px;}
   .percentage-card h3{font-size: 40px;}
}
@media (max-width: 1399.98px){
    .budget-allocation h4{margin-bottom: 40px; font-size: 40px;}
    .percentage-card{min-height: 190px;margin: 10px 0px;}
    .dot {margin-bottom: 20px; height: 17px;width: 17px;border-radius: 50%;}
   .percentage-card p{width: 159px; font-size: 15px;margin-bottom: 4px;}
   .percentage-card h3{font-size: 40px;}
}
@media (max-width: 1199.98px) {
    .budget-allocation h4{margin-bottom: 0px; font-size: 40px;}
    .budget-allocation .row{align-items: center;}
    .percentage-card{min-height: 190px;margin: 5px 0px;}
    .dot {margin-bottom: 20px; height: 13px;width: 13px;border-radius: 50%;}
   .percentage-card p{width: 109px; font-size: 14px;margin-bottom: 4px;}
   .percentage-card h3{font-size: 30px;}
}
@media (max-width: 991.98px) {
    .budget-allocation{padding-top: 30px;}
    .budget-allocation-image img{width: 400px;}
    .budget-allocation h4{margin-bottom: 0px; line-height: 32px;font-size: 30px;}
    .percentage-card{min-height: 150px;margin: 20px 0px;}
    .dot { height: 13px;width: 13px;margin-bottom: 10px;}
   .percentage-card p{width: 100%; line-height: 18x;font-size: 16px;}
   .percentage-card h3{line-height: 26px;font-size: 30px;}
   .custom-prev {top: -67%;}
     .custom-next {top: -67%;}
   .team-members img{width: 100%;}
   .team-gallery .content h4{font-size: 30px;line-height: 32px;}
   .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
   .team-members .figure-caption h2{font-size: 13px;margin-bottom: 2px;text-align: center;}
   .team-members .figure-caption p{font-size: 12px;}
}
@media (max-width:767px) {
    .budget-allocation{padding-top: 30px;margin-bottom: 50px;}
    .budget-allocation h4{margin-bottom: 30px; line-height: 32px;font-size: 30px;}
    .percentage-card{min-height: 150px;margin: 20px 0px;}
    .dot { height: 13px;width: 13px;margin-bottom: 10px;}
   .percentage-card p{width: 100%; line-height: 34px;font-size: 16px;}
   .percentage-card h3{line-height: 26px;font-size: 30px;}
   .team-members img{width: 100%;}
   .team-gallery .content h4{font-size: 30px;line-height: 32px;}
   .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
   .team-members .figure-caption p{font-size: 12px; text-align: center;}
}
@media (max-width:567px){
    .budget-allocation{padding-top: 30px;margin-bottom: 50px;}
    .budget-allocation h4{margin-bottom: 30px; line-height: 32px;font-size: 30px;}
    .percentage-card{min-height: 150px;margin: 20px 0px;}
    .dot { height: 13px;width: 13px;margin-bottom: 10px;}
   .percentage-card p{width: 100%; line-height: 34px;font-size: 16px;}
   .percentage-card h3{line-height: 26px;font-size: 30px;}
   .team-members img{width: 100%;}
   .team-gallery .content h4{font-size: 30px;line-height: 32px;}
   .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
   .team-members .figure-caption h2{font-size: 22px;margin-bottom: 2px;}
   .team-members .figure-caption p{font-size: 12px; text-align: center;}
}
@media (max-width:480px){
    .budget-allocation{padding-top: 30px;margin-bottom: 50px;}
    .budget-allocation h4{margin-bottom: 30px; line-height: 32px;font-size: 30px;}
    .percentage-card{min-height: 150px;margin: 20px 0px;}
    .dot { height: 13px;width: 13px;margin-bottom: 10px;}
   .percentage-card p{width: 100%; line-height: 34px;font-size: 16px;}
   .percentage-card h3{line-height: 26px;font-size: 30px;}
   .team-members img{width: 100%;}
   .team-gallery .content h4{font-size: 30px;line-height: 32px;}
   .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
   .team-members .figure-caption h2{font-size: 22px;margin-bottom: 2px;}
   .team-members .figure-caption p{font-size: 12px; text-align: center;}
}
@media (max-width:367px){
    .budget-allocation{padding-top: 30px;margin-bottom: 50px;}
    .budget-allocation h4{margin-bottom: 30px; line-height: 32px;font-size: 30px;}
    .percentage-card{min-height: 150px;margin: 20px 0px;}
    .dot { height: 13px;width: 13px;margin-bottom: 10px;}
   .percentage-card p{width: 100%; line-height: 34px;font-size: 16px;}
   .percentage-card h3{line-height: 26px;font-size: 30px;}
   .team-members img{width: 100%;}
   .team-gallery .content h4{font-size: 30px;line-height: 32px;}
   .team-gallery .content p{margin-bottom: 30px; line-height: 26px;font-size: 16px;width: 100%;}
   .team-members .figure-caption h2{font-size: 22px;margin-bottom: 2px;}
   .team-members .figure-caption p{font-size: 12px; text-align: center;}
}
@media (max-width:280px){
    .budget-allocation h4 {margin-bottom: -8px;font-size: 25px;}
}