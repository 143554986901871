/* Responsive Timeline */
.timeline{display: none;}
.timeline{position:relative;margin:50px auto;padding:40px 0;width:1000px;box-sizing:border-box;}
.timeline:before{content:'';position:absolute;left:50%;width:2px;height:100%;background: rgba(17, 17, 21, 0.1);}
.timeline ul{padding:0;margin:0;}
.timeline ul li{list-style:none;position:relative;width:50%;padding:20px 40px;box-sizing:border-box;}
.timeline ul li:nth-child(odd){float:left;text-align:right;clear:both;}
.timeline ul li:nth-child(even){float:right;text-align:left;clear:both}
.content{padding-bottom:20px;}
.timeline ul li:nth-child(odd):before
{content:'';position:absolute;width:10px;height:10px;top:24px;right:-6px;background:#D62287;border-radius:50%;}
.timeline ul li:nth-child(even):before
{content:'';position:absolute;width:10px;height:10px;top:24px;left:-4px;background:#D62287;border-radius:50%;}
.timeline ul li h3{padding:0;margin:0;color:rgba(233,33,99,1);font-weight:600;}
.timeline ul li p{margin:10px 0 0;padding:0;}
.timeline ul li .time h4{margin:0;padding:0;font-size:14px;}
.timeline ul li:nth-child(odd) .time
{position:absolute;top:12px;right:-165px;margin:0;padding:8px 16px;background:#D62287;color:#fff;border-radius:18px;}
.timeline ul li:nth-child(even) .time
{position:absolute;top:12px;left:-165px;margin:0;padding:8px 16px;background:#D62287;color:#fff;border-radius:18px;}
@media (max-width:767px) {
    .ecosytem{display: none;}
  .timeline{display: block;}
    .timeline{
        width:100%;
        padding-bottom:0;
      }
      h1{
        font-size:40px;
        text-align:center;
      }
      .timeline:before{
        left:20px;
        height:100%;
      }
      .timeline ul li:nth-child(odd),
      .timeline ul li:nth-child(even)
      {
        width:100%;
        text-align:left;
        padding-left:50px;
        padding-bottom:50px;
      }
      .timeline ul li:nth-child(odd):before,
      .timeline ul li:nth-child(even):before
      {
        top:-18px;
        left:16px;
      }
      .timeline ul li:nth-child(odd) .time,
      .timeline ul li:nth-child(even) .time{
        top:-30px;
        left:50px;
        right:inherit;
      }
}
@media (max-width:567px){   .ecosytem{display: none;}
.timeline{display: block;}}
@media (max-width:480px){
    .ecosytem{display: none;}
.timeline{display: block;}
}
@media (max-width:367px){
.ecosytem{display: none;}
.timeline{display: block;}
}
@media (max-width:280px){}