.sponser{background-color: #FAFBFD;padding: 80px 0 60px 0;}
 .sponser-img{display: flex;justify-content: space-between;max-width: 1794px;margin: 0 auto;}
 .sponser-img img{width: 250px;height: 90px;margin-bottom: 20px; }
 @media (max-width: 1799.98px){
    .sponser .sponser-img img {width: 220px;}
 }
@media (max-width: 1399.98px){   .sponser .sponser-img img {width: 180px;}}
@media (max-width: 1199.98px) {   .sponser .sponser-img img {width: 150px;}}
@media (max-width: 991.98px)  {.sponser-img{flex-wrap: wrap;justify-content: space-around;}.sponser-img img{width: 100%;}}
@media (max-width:767px) {.sponser-img{flex-wrap: wrap;justify-content: space-between;}}
@media (max-width:567px){
   .sponser-img{flex-wrap: wrap;}
   .sponser-img img{width: 250px !important;}
}
