/* banner */
.banner{padding-top: 302px;padding-bottom: 200px; position: relative;background-image: url("../../images/banner.jpg");}
.banner .banner-content{text-align: center;}
.banner .banner-content h6{margin-bottom: 10px; color: #D32986;font-size: 24px;font-family: 'Filson Pro Bold';text-transform: uppercase;font-weight: 700;letter-spacing: 7px;}
.banner .banner-content h2{font-weight: 700; margin-bottom: 50px; font-size: 70px;line-height: 90px;color: #000;font-family: 'Filson Pro Bold';}
.banner .banner-content h2 span{text-transform: none;color:#d32986;}
.banner .banner-content p{text-align: center; margin: 0 auto 100px auto; color: #000;font-size: 26px;line-height: 36px;max-width: 1220px;font-family: 'Filson Pro Regular';}
.banner .banner-content button{border: none; position: relative; font-family: 'Filson Pro Regular'; font-size: 22px; color: #FFFFFF;padding: 18px 45px;background-color: #d62287;border-radius: 10px;}
.banner .banner-content button:focus{box-shadow: none;}
@media (max-width: 1799.98px){
    .banner .banner-content h6{ font-size: 30px;margin-bottom: 20px;}
    /* .banner .banner-content h2{ font-size: 80px;line-height: 90px;} */
    .banner .banner-content p{font-size: 22px;line-height: 30px;}
    .banner .banner-content button{ font-size: 26px;padding: 20px 40px;}
}
@media (max-width: 1399.98px){
.banner-content h2{font-size: 62px;}
.sponser .sponser-img img {width: 220px;}
.banner{padding-top: 170px;padding-bottom: 100px;}
.banner .banner-content p{margin: 0 auto 50px auto;}
.sponser {padding: 50px 0 40px 0;}
.banner .banner-content h2{ font-size: 60px;line-height: 70px;}
.banner .banner-content p{font-size: 22px;line-height: 30px;}
.banner .banner-content button{ font-size: 22px;padding: 15px 30px;}
}
@media (max-width: 1199.98px) {
    .banner .banner-content h6{ font-size: 15px; letter-spacing: 4px;}
  .banner .banner-content h2{ font-size: 40px;line-height: 50px;margin-bottom: 30px;}
  .banner .banner-content p{font-size: 16px;line-height: 30px;}
  .banner .banner-content button{ font-size: 20px;padding: 15px 25px;}
}
@media (max-width: 991.98px) {
    .banner .banner-content h6{ font-size: 15px; letter-spacing: 4px;}
  .banner .banner-content h2{ font-size: 35px;line-height: 40px;}
  .banner .banner-content p{font-size: 16px;line-height: 22px;margin-bottom: 30px;}
  .banner .banner-content button{ font-size: 20px;padding: 9px 18px;}
}
@media (max-width:767px) {
    .banner .banner-content{padding-top: 10px;}
  .banner .banner-content h6{font-size: 24px;margin-bottom: 20px;}
  .banner .banner-content h2{ font-size: 20px;line-height: 28px;}
  .banner .banner-content p{margin-bottom: 30px; font-size: 16px;line-height: 20px;font-weight: normal;}
  .banner .banner-content button{ font-size: 16px;padding: 12px 20px;}
}
@media (max-width:567px){
  .banner{padding-top: 67px;padding-bottom: 50px;}
  .banner .banner-content h6{font-size: 24px;margin-bottom: 20px;}
  /* .banner .banner-content h2{ font-size: 20px;line-height: 28px;} */
  .banner .banner-content p{margin-bottom: 30px; font-size: 13px;line-height: 20px;font-weight: normal;}
  .banner .banner-content button{ font-size: 16px;padding: 12px 20px;}
}
@media (max-width:480px){
    .banner .banner-content h6{font-size: 17px;margin-bottom: 20px;}
    /* .banner .banner-content h2{ font-size: 25px;line-height: 28px;} */
    .banner .banner-content p{margin-bottom: 30px; font-size: 16px;line-height: 20px;font-weight: normal;}
    .banner .banner-content button{ font-size: 16px;padding: 12px 20px;}
}
@media (max-width:367px){
.banner .banner-content h6{font-size: 17px;margin-bottom: 20px;width: 250px;}
/* .banner .banner-content h2{ font-size: 25px;line-height: 28px;} */
.banner .banner-content p{margin-bottom: 30px; font-size: 12px;line-height: 20px;font-weight: normal;}
.banner .banner-content button{ font-size: 16px;padding: 12px 20px;}
}
@media (max-width:280px){}