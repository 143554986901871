/* Invest In Yourself */
.invest-in-yourself{padding-top: 100px;}
.rxhc-content{padding-left: 40px;}
.token-content{padding-top: 30px;}
.purchase-limit{margin-bottom: 50px;}
.invest-in-yourself .content h2{margin-bottom: 30px; font-family: 'Filson Pro Bold';font-style: normal;font-weight: bold;font-size:45px;line-height: 65px;color: #4A4A4A;}
.invest-in-yourself .content h2 span{color:#d32986;}
.invest-in-yourself .content p{width: 1000px; margin: 0 auto 100px auto; font-style: normal;font-weight: normal;font-size: 26px; line-height: 36px;color: rgba(17, 17, 21, 0.5);font-family: 'Filson Pro Regular';}
.invest-in-yourself .rxhc-card{background-image: url('../../images/Union.svg');background-repeat: no-repeat;background-size: 100% 100%; padding-top: 60px;padding-left: 30px;padding-right: 30px;}
.rxhc-image{background-color: #ffffff;padding: 40px 25px;border-radius: 30px;}
.rxhc-content p{font-style: normal;font-weight: bold;font-size: 36px; line-height: 48px;font-family: 'Filson Pro Bold'; color: #4A4A4A;}
 span> sub{padding-left: 10px; font-size: 22px;font-weight: 400;font-family: 'Filson Pro Regular';color: rgba(17, 17, 21, 0.5);}
 .rxhc-content h2{font-family: 'Filson Pro Bold'; font-style: normal;font-weight: bold;color: #4A4A4A;line-height: 24px; font-size: 24px;}
 .rxhc-content-counting  > span{font-style: normal;font-weight: normal;font-size: 24px;line-height: 24px;color: rgba(17, 17, 21, 0.5);font-family: 'Filson Pro Regular';}
 .rxhc-card .token-content h5{font-family: 'Filson Pro Regular'; font-style: normal;font-weight: normal;font-size: 22px;line-height: 42px; color: rgba(17, 17, 21, 0.5);}
 .rxhc-card .token-content p{padding-right: 90px; font-family: 'Filson Pro Bold';font-style: normal;font-weight: bold;font-size: 22px;line-height: 42px;color: #4A4A4A;}
 .launch-date{margin-bottom: 30px; font-family: 'Filson Pro Regular';font-style: normal;font-weight: normal;font-size: 22px;line-height: 22px;color: rgba(17, 17, 21, 0.5);}
 #countdown li {text-align: center; display: inline-block;font-size: 26px;list-style-type: none;text-align: left;padding: 20px;text-transform: uppercase;font-family: 'Filson Pro Bold';font-style: normal;font-weight: bold; line-height: 32px;color: #4A4A4A;}
 #countdown li span {display: block;font-size: 4.5rem;}
 .countdown-content{padding: 40px 5px; font-family: 'Filson Pro Regular';font-style: normal;font-weight: normal;font-size: 18px;line-height: 22px;color: rgba(17, 17, 21, 0.5);}
 .token-content-members{padding-left: 50px;}
 .token-content-members h3{margin-bottom: 35px; padding-top: 80px; font-family: 'Filson Pro Bold';font-style: normal;font-weight: bold;font-size: 45px;line-height: 65px;color: #4A4A4A;}
 .token-content-members h3 span{color:#d32986;}
 .token-content-members p{font-family: 'Filson Pro Regular';font-style: normal;font-weight: normal;font-size: 22px; line-height: 32px;  color: rgba(17, 17, 21, 0.5);}
 .token-content-members button{position: relative; background-color: #d32986; font-family: 'Filson Pro Regular';font-style: normal;font-size: 22px;line-height: 22px;text-align: center;color: #FFFFFF;border-radius: 10px;padding: 23px 45px;}
 .token-content-members span{padding-left: 50px; font-family: 'Filson Pro Bold';font-style: normal;font-weight: normal;font-size: 22px;line-height: 30px;color: #4A4A4A;}
 .token-content-members span a{text-decoration: none;color: #4A4A4A;}
 .tokenomics-para{margin-bottom: 100px;}
.tokenomics-para p{max-width: 670px;}
@media (max-width: 1799.98px){
    .token-content{padding-top: 10px;}
  .invest-in-yourself .content h2{margin-bottom: 10px; font-size: 40px;}
  .invest-in-yourself .content p{margin-bottom: 70px; width: 830px;text-align: center; margin: 10px auto;font-size: 17px;}
  .invest-in-yourself .rxhc-card{ padding-top: 50px;padding-left: 80px;padding-right: 80px;}
  .rxhc-image{padding: 10px 15px;}
  .rxhc-image img{width: 50px;}
  .rxhc-content p{font-style: normal;font-size: 19px; line-height: 38px;}
   span> sub{padding-left: 2px; font-size: 14px;}
   .rxhc-content h2{ font-size: 18px;}
   .rxhc-content-counting  > span{font-size: 16px;line-height: 18px;}
   .rxhc-card .token-content h5{font-size: 15px;line-height: 32px;}
   .rxhc-card .token-content p{padding-right: 60px;font-size: 15px;line-height: 32px;margin-bottom: 5px;}
   .launch-date{font-size: 16px;line-height: 22px;color: rgba(17, 17, 21, 0.5);}
   #countdown li {font-size: 16px;padding:12px; line-height: 22px;text-align: center;}
   #countdown li span {display: block;font-size: 2.4rem;}
   .countdown-content{padding: 30px 3px; font-size: 14px;margin-bottom: 40px;}
   .token-content-members h3{margin-bottom: 35px; padding-top: 80px; font-size: 40px;line-height: 52px;}
   .token-content-members h3 span{color:#d32986;position: relative;left: -8%;}
   .token-content-members p{font-size: 20px; line-height: 26px;}
   .token-content-members button{ font-size: 20px;padding: 17px 34px;}
   .token-content-members span{font-size: 17px;}
   .experties p {
    width: 100%;
    line-height: 22px;
    font-size: 16px;
}
}
@media (max-width: 1399.98px){
    .token-content{padding-top: 10px;}
.invest-in-yourself .content h2{margin-bottom: 10px; font-size: 40px;}
.invest-in-yourself .content p{margin-bottom: 70px; width: 830px;text-align: center; margin: 10px auto;font-size: 17px;}
.invest-in-yourself .rxhc-card{ padding-top: 80px;padding-left: 30px;padding-right: 30px;}
.rxhc-image{padding: 10px 15px;}
.rxhc-image img{width: 50px;}
.rxhc-content p{font-style: normal;font-size: 19px; line-height: 38px;}
 span> sub{padding-left: 2px; font-size: 14px;}
 .rxhc-content h2{ font-size: 18px;}
 .rxhc-content-counting  > span{font-size: 16px;line-height: 18px;}
 .rxhc-card .token-content h5{font-size: 15px;line-height: 32px;}
 .rxhc-card .token-content p{padding-right: 60px;font-size: 15px;line-height: 32px;margin-bottom: 5px;}
 .launch-date{font-size: 16px;line-height: 22px;color: rgba(17, 17, 21, 0.5);}
 #countdown li {font-size: 16px;padding:12px; line-height: 2px;text-align: center;}
 #countdown li span {display: inline-block;font-size: 2rem;}
 .countdown-content{padding: 10px 2px; font-size: 10px;margin-bottom: 40px;}
 .token-content-members h3{margin-bottom: 35px; padding-top: 80px; font-size: 35px;line-height: 42px;}
 .token-content-members h3 span{color:#d32986;position: relative;left: -8%;}
 .token-content-members p{font-size: 18px; line-height: 22px;}
 .token-content-members button{ font-size: 20px;padding: 17px 34px;}
 .token-content-members span{font-size: 17px;}
}
@media (max-width: 1199.98px) {
    .tokenomics-para {
        margin-bottom: 50px;
      }
      .invest-in-yourself .rxhc-card{ padding-top: 30px;padding-left: 30px;padding-right: 30px;}

    .token-content{padding-top: 5px;}
    .purchase-limit{margin-bottom: 10px;}
    .invest-in-yourself .content h2{margin-bottom: 10px; font-size: 30px;}
    .invest-in-yourself .content p{margin-bottom: 30px; width: 700px;text-align: center; margin: 10px auto;font-size: 15px;line-height: 18px;}
    .rxhc-content p{font-style: normal;font-size: 14px; }
     span> sub{padding-left: 2px; font-size: 14px;}
     .rxhc-content h2{ font-size: 14px;}
     .rxhc-content-counting  > span{font-size: 13px;line-height: 18px;}
     .rxhc-card .token-content h5{font-size: 12px;line-height: 22px;}
     .rxhc-card .token-content p{padding-right: 60px;font-size: 12px;line-height: 22px;margin-bottom: 5px;}
     .launch-date{font-size: 14px;line-height: 12px;margin-bottom: 30px;}
     #countdown li {font-size: 12px;padding:5px; line-height: 2px;text-align: center;}
     #countdown li span {font-size: 1.3rem;}
     .countdown-content{padding: 15px 1px; font-size: 12px;margin-bottom: 0px;}
     .token-content-members h3{margin-bottom: 35px; padding-top: 80px;font-size: 30px;line-height: 32px;}
     .token-content-members h3 span{color:#d32986;position: relative;left: -10%;}
     .token-content-members p{ font-size: 15px; line-height:18px;}
     .token-content-members button{ font-size: 15px;padding: 10px 15px;}
     .token-content-members span{font-size: 14px;}
}
@media (max-width: 991.98px) {
  .invest-in-yourself{padding-top: 50px;}
  .invest-in-yourself .content h2{font-size: 20px;line-height: 32px;width: 100%;}
  .invest-in-yourself .content p{margin-bottom: 50px; width: 100%; margin: 0px auto;font-size: 16px; line-height: 20px;}
  .invest-in-yourself .rxhc-card{ padding-top: 10px;padding-left: 12px;padding-right: 12px;}
  .invest-in-yourself .rxhc-card{background-image: unset;background-color: rgba(237, 238, 239, 0.4);border-radius: 25px;}
  .rxhc-image{background-color: #ffffff;padding: 10px 20px;margin-top: 25px;}
  .rxhc-image img{width: 20px;}
  .rxhc-content p{font-size: 12px; line-height: 18px;padding-top: 20px;}
   span sub{padding-left: 5px; font-size: 12px;}
   .rxhc-content h2{color: #4A4A4A;line-height: 14px; font-size: 12px;}
   .rxhc-content-counting  > span{font-size: 12px;line-height: 24px;}
   .rxhc-content{padding-left: 20px;padding-top: 20px;}
   .rxhc-card .token-content h5{ font-size: 12px;line-height: 22px;white-space: nowrap;}
   .rxhc-card .token-content p{padding-right: 0px; font-size: 10px;line-height: 22px;white-space: nowrap;}
   .launch-date{font-size: 12px;line-height: 20px;}
   #countdown li {font-size: 12px;padding: 3px; line-height: 0px;}
   #countdown li span {display: block;font-size: 23px;}
   .countdown-content{padding: 5px 0px;font-size: 12px;line-height: 32px;}
   .token-content-members{padding-left: 0px;}
   .token-content-members h3{margin-bottom: 35px; padding-top: 30px;font-size: 20px;line-height: 25px;}
   .token-content-members p{font-size: 16px; line-height: 26px;}
   .token-content-members button{ font-size: 14px;line-height: 22px;padding: 13px 25px;}
   .token-content-members span{padding-left: 20px; font-size: 14px;}
   .experties{padding-top: 50px;}
}
@media (max-width:767px) {
    .invest-in-yourself{padding-top: 50px;}
  .invest-in-yourself .content h2{font-size: 20px;line-height: 32px;width: 100%;}
  .invest-in-yourself .content p{margin-bottom: 50px; width: 100%; margin: 0px auto;font-size: 16px; line-height: 20px;}
  .invest-in-yourself .rxhc-card{ padding-top: 10px;padding-left: 12px;padding-right: 12px;}
  .invest-in-yourself .rxhc-card{background-image: unset;background-color: rgba(237, 238, 239, 0.4);border-radius: 25px;}
  .rxhc-image{background-color: #ffffff;padding: 10px 20px;margin-top: 25px;}
  .rxhc-image img{width: 20px;}
  .rxhc-content p{font-size: 12px; line-height: 18px;padding-top: 20px;}
   span sub{padding-left: 5px; font-size: 12px;}
   .rxhc-content h2{color: #4A4A4A;line-height: 14px; font-size: 12px;}
   .rxhc-content-counting  > span{font-size: 12px;line-height: 24px;}
   .rxhc-content{padding-left: 20px;padding-top: 20px;}
   .rxhc-card .token-content h5{ font-size: 12px;line-height: 22px;white-space: nowrap;}
   .rxhc-card .token-content p{padding-right: 0px; font-size: 12px;line-height: 22px;white-space: nowrap;}
   .launch-date{font-size: 12px;line-height: 20px;}
   #countdown li {font-size: 12px;padding: 3px; line-height: 0px;}
   #countdown li span {display: block;font-size: 23px;}
   .countdown-content{padding: 5px 0px;font-size: 12px;line-height: 32px;}
   .token-content-members{padding-left: 0px;}
   .token-content-members h3{margin-bottom: 35px; padding-top: 30px;font-size: 20px;line-height: 25px;}
   .token-content-members p{font-size: 16px; line-height: 26px;}
   .token-content-members button{ font-size: 14px;line-height: 22px;padding: 13px 25px;}
   .token-content-members span{padding-left: 20px; font-size: 14px;}
   .experties{padding-top: 50px;}
}
@media (max-width:567px){
  .invest-in-yourself .rxhc-card {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
    .invest-in-yourself{padding-top: 50px;}
  .invest-in-yourself .content h2{font-size: 20px;line-height: 32px;width: 100%;}
  .invest-in-yourself .content p{margin-bottom: 50px; width: 100%; margin: 0px auto;font-size: 16px; line-height: 20px;}
  .rxhc-image{background-color: #ffffff;padding: 10px 20px;margin-top: 25px;}
  .rxhc-image img{width: 20px;}
  .rxhc-content p{font-size: 12px; line-height: 18px;padding-top: 20px;}
   span sub{padding-left: 5px; font-size: 12px;}
   .rxhc-content h2{color: #4A4A4A;line-height: 14px; font-size: 12px;}
   .rxhc-content-counting  > span{font-size: 12px;line-height: 24px;}
   .rxhc-content{padding-left: 20px;padding-top: 20px;}
   .rxhc-card .token-content h5{ font-size: 12px;line-height: 22px;white-space: nowrap;}
   .launch-date{font-size: 12px;line-height: 20px;}
   #countdown li {font-size: 12px;padding: 3px; line-height: 0px;text-align: center;}
   #countdown li span {display: block;font-size: 23px;}
   .countdown-content{padding: 5px 0px;font-size: 12px;line-height: 32px;}
   .token-content-members{padding-left: 0px;}
   .token-content-members h3{margin-bottom: 35px; padding-top: 30px;font-size: 20px;line-height: 25px;}
   .token-content-members p{font-size: 16px; line-height: 26px;}
   .token-content-members button{ font-size: 14px;line-height: 22px;padding: 13px 25px;}
   .token-content-members span{padding-left: 20px; font-size: 14px;}
   .experties {
    padding-top: 20px;
  }
   
}
@media (max-width:480px){
    .invest-in-yourself{padding-top: 50px;}
    .invest-in-yourself .content h2{font-size: 20px;line-height: 32px;width: 100%;}
    .invest-in-yourself .content p{margin-bottom: 50px; width: 100%; margin: 50px auto;font-size: 16px; line-height: 20px;}
    .rxhc-image{background-color: #ffffff;padding: 10px 20px;margin-top: 25px;}
    .rxhc-image img{width: 20px;}
    .rxhc-content p{font-size: 12px; line-height: 18px;padding-top: 20px;}
     span sub{padding-left: 5px; font-size: 12px;}
     .rxhc-content h2{color: #4A4A4A;line-height: 14px; font-size: 12px;}
     .rxhc-content-counting  > span{font-size: 12px;line-height: 24px;}
     .rxhc-content{padding-left: 20px;padding-top: 20px;}
     .rxhc-card .token-content h5{ font-size: 12px;line-height: 22px;white-space: nowrap;}
     .launch-date{font-size: 12px;line-height: 20px;}
     #countdown li {font-size: 12px;padding: 3px; line-height: 0px;text-align: center;}
     #countdown li span {display: block;font-size: 23px;}
     .countdown-content{padding: 5px 0px;font-size: 12px;line-height: 32px;}
     .token-content-members{padding-left: 0px;}
     .token-content-members h3{margin-bottom: 35px; padding-top: 30px;font-size: 20px;line-height: 25px;}
     .token-content-members p{font-size: 16px; line-height: 26px;}
     .token-content-members button{ font-size: 14px;line-height: 22px;padding: 13px 25px;}
     .token-content-members span{padding-left: 20px; font-size: 14px;}
     .experties {padding-top: 23px;}
      .experties p{font-size: 14px; line-height: 18px;}
}
@media (max-width:367px){
.custom-container{padding-left: 20px;padding-right: 20px;}
.invest-in-yourself{padding-top: 50px;}
.invest-in-yourself .content h2{font-size: 20px;line-height: 32px;width: 100%;}
.invest-in-yourself .content p{ width: 100%; margin: 20px auto;font-size: 16px; line-height: 20px;}
.invest-in-yourself .rxhc-card{ padding-top: 10px;padding-left: 10px;padding-right: 10px;}
.rxhc-image{padding: 10px 20px;margin-top: 25px;}
.rxhc-image img{width: 20px;}
.rxhc-content p{font-size: 12px; line-height: 18px;padding-top: 20px;}
 span sub{padding-left: 5px; font-size: 12px;}
 .rxhc-content h2{color: #4A4A4A;line-height: 14px; font-size: 12px;}
 .rxhc-content-counting  > span{font-size: 12px;line-height: 24px;}
 .rxhc-content{padding-left: 20px;padding-top: 20px;}
 .rxhc-card .token-content h5{ font-size: 12px;line-height: 22px;white-space: nowrap;}
 .launch-date{font-size: 12px;line-height: 20px;}
 #countdown li {font-size: 12px;padding: 3px; line-height: 0px;text-align: center;}
 #countdown li span {display: block;font-size: 23px;}
 .countdown-content{padding: 5px 0px;font-size: 12px;line-height: 32px;}
 .token-content-members{padding-left: 0px;}
 .token-content-members h3{margin-bottom: 35px; padding-top: 30px;font-size: 20px;line-height: 25px;}
 .token-content-members p{font-size: 16px; line-height: 26px;}
 .token-content-members button{ font-size: 14px;line-height: 22px;padding: 8px 12px;}
 .token-content-members span{padding-left: 20px; font-size: 14px;}
 .experties {padding-top: 23px;}
.experties p{font-size: 14px; line-height: 20px;}
}
@media (max-width:280px){
    .token-content-members button {font-size: 14px;padding: 8px 18px;}
    .token-content-members h3 {margin-bottom: 14px;font-size: 17px;}
    .launch-date {font-size: 10px;line-height: 11px;}
    .countdown-content {padding: 1px 0px;font-size: 10px;line-height: 31px;}
    #countdown li span {font-size: 16px;}
    .experties {padding-top: 23px;}
  .experties p{font-size: 12px;}
}